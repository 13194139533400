import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import Team from '@mangoart/gatsby-ui/components/ezagrar/Vario-Fendt-Schaeffer/Team';
import DefaultLayout from '../../layout/DefaultLayout';
import { Icon } from '@mangoart/gatsby-ui/components/ezagrar/Icon';

const TeamPage = ({ data, location }) => {
  const { pageData, siteData, employees, departments } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph, content } = pageData;

  const filterEmployees = {
    edges: employees.edges.filter(
      (employee) => !employee.node.exclude || !employee.node.exclude.includes('lamborghini-traktoren.at'),
    ),
  };

  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <div className={'defaultWidth'}>
        <PageTitle>
          {content.icon && <Icon icon={content.icon} />}
          {content.title}
        </PageTitle>
      </div>
      <Team employees={filterEmployees} departments={departments} showNavigation={true} />
    </DefaultLayout>
  );
};

export default TeamPage;

export const TeamPageQuery = graphql`
  query TeamPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageTeam {
      content {
        title
        icon
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
    departments: allCockpitAbteilung(filter: { sites: { in: "lamborghini-traktoren.at" } }) {
      nodes {
        id
        Bezeichnung
        slug
        Gruppierung {
          Gruppierung {
            id
          }
        }
      }
    }
    employees: allCockpitMitarbeiter {
      edges {
        node {
          id
          Name
          company
          exclude
          departments {
            id
            Bezeichnung
          }
          Email
          Telefon
          Aufgabenbereiche
          Mobiltelefon
          Fax
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
